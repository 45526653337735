import React from 'react';
import './speedometer.scss';
import { ReactComponent as E1 } from '../../../assets/svg/E1.svg';
import { ReactComponent as E2 } from '../../../assets/svg/E2.svg';
import { ReactComponent as E3 } from '../../../assets/svg/E3.svg';

interface SpeedometerProps {
    status: 'low' | 'medium' | 'high';
}

const Speedometer: React.FC<SpeedometerProps> = ({ status }) => {
    const paths = {
        low: [<E1 key="low" />],
        medium: [<E1 key="medium1" />, <E2 key="medium2" />],
        high: [<E1 key="high1" />, <E2 key="high2" />, <E3 key="high3" />],
    };

    return (
        <div className={`speedometer-widget ${status}`}>
            <div className="speedometer-title">Importance</div>
            <div className="speedometer-status">{status}</div>
            <div className="speedometer-chart">
                {paths[status]}
            </div>
        </div>
    );
};

export default Speedometer;
