import { ReactComponent as Exclamation } from '../../../assets/svg/exclamation-circle_96.svg';
import { ReactComponent as HighMeter } from '../../../assets/svg/high-meter_96.svg';
import { ReactComponent as CorruptedNetwork } from '../../../assets/svg/corrupted-network_96.svg';

export const StatusColors = {
    low: {
        color: '#848AFF',
        endColor: 'rgba(102, 16, 242, 0.2)',
        gradientId: 'gradientLow'
    },
    medium: {
        color: '#EA712F',
        endColor: 'rgba(234, 113, 47, 0.2)',
        gradientId: 'gradientMedium'
    },
    high: {
        color: '#FF5D65',
        endColor: '#962329',
        gradientId: 'gradientHigh'
    }
};


export const CATEGORIES_CONFIG = [
    { fieldName: 'attacks', displayName: 'Attacks', color: '#CD11EB', gradientId: 'gradient-attacks', icon: CorruptedNetwork },
    { fieldName: 'threats', displayName: 'Threats', color: '#0149D9', gradientId: 'gradient-threats', icon: Exclamation },
    { fieldName: 'weaknesses', displayName: 'Weaknesses', color: '#009FDB', gradientId: 'gradient-weaknesses', icon: HighMeter },
];

export interface DataItem {
    id: string;
    useCaseName: string;
    importance: 'high' | 'medium' | 'low';
    deviceNumber: number;
    trend: 'up' | 'down';
    apnNumber: number;
    category: 'attacks' | 'threats' | 'weaknesses';
}

export const HELP_INFO_DESC = 'Tools and resources to help you build applications using our self-service APIs for your language of choice.';

export const FOOTER_TEXT = '© 2023 AT&T Intellectual Property. All rights reserved. AT&T, the AT&T logo and all other AT&T marks contained herein are trademarks of AT&T intellectual property and/or AT&T affiliated companies. All other marks are the property of their respective owners.';