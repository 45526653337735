import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import './donut-chart.scss';
import moment from 'moment/moment';
import { StatusColors } from '../security-dashboard.constant';

const DonutChart = ({ data, totalValue }) => {
    const ref = useRef();

    useEffect(() => {
        const filteredData = data.filter(d => d.value > 0);
        const svg = d3.select(ref.current);
        const width = 280;
        const height = 280;
        const radius = Math.min(width, height) / 2;

        svg.attr('width', width).attr('height', height);

        const defs = svg.append('defs');

        // Define gradients based on StatusColors
        Object.keys(StatusColors).forEach(status => {
            const gradient = defs.append('linearGradient')
                .attr('id', StatusColors[status].gradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '100%');

            gradient.append('stop')
                .attr('offset', '16.54%')
                .attr('stop-color', StatusColors[status].color);

            gradient.append('stop')
                .attr('offset', '88.26%')
                .attr('stop-color', StatusColors[status].endColor);
        });

        const g = svg.append('g')
            .attr('transform', `translate(${width / 2},${height / 2})`);

        const pie = d3.pie()
            .value(d => d.value)
            .sort(null)
            .padAngle(0.01);

        const arc = d3.arc()
            .innerRadius(radius - 40)
            .outerRadius(radius)
            .cornerRadius(5);

        const arcHover = d3.arc()
            .innerRadius(radius - 60)
            .outerRadius(radius)
            .cornerRadius(5);

        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0);

        const arcs = g.selectAll('.arc')
            .data(pie(filteredData))
            .enter()
            .append('g')
            .attr('class', 'arc')
            .on('mouseover', function(event, d) {
                d3.select(this).select('path')
                    .transition()
                    .duration(200)
                    .attr('d', arcHover);

                tooltip.transition()
                    .duration(200)
                    .style('opacity', .9);

                tooltip.html(`
                    <div class="tooltip-title">${d.data.status}<div class="tooltip-title-count" style="color:${StatusColors[d.data.status].color};">${d.data.value}</div></div>
                    <div class="tooltip-devices" style="color:${StatusColors[d.data.status].color};">Impacting ${d.data.devices} devices</div>
                    <div class="tooltip-date">${moment(d.data.date).format('MMMM D, YYYY, h:mm A z')}</div>
                `)
                    .style('left', (event.pageX + 10) + 'px')
                    .style('top', (event.pageY - 28) + 'px');
            })
            .on('mouseout', function(event, d) {
                d3.select(this).select('path')
                    .transition()
                    .duration(200)
                    .attr('d', arc);

                tooltip.transition()
                    .duration(500)
                    .style('opacity', 0);
            });

        if (filteredData.length > 1) {
            arcs.append('path')
                .attr('d', arc)
                .attr('fill', d => `url(#${StatusColors[d.data.status].gradientId})`);

            arcs.append('text')
                .attr('transform', d => `translate(${arc.centroid(d)})`)
                .attr('text-anchor', 'middle')
                .attr('dy', '.35em')
                .text(d => d.data.value)
                .attr('fill', 'white')
                .attr('font-size', '18px')
                .attr('font-family', 'ATTAleckSansMd');
        } else if (filteredData.length === 1) {
            arcs.append('path')
                .attr('d', d3.arc()
                    .innerRadius(radius - 40)
                    .outerRadius(radius)
                    .startAngle(0)
                    .endAngle(2 * Math.PI))
                .attr('fill', `url(#${StatusColors[filteredData[0].status].gradientId})`);
        }

        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('font-size', '46px')
            .attr('font-family', 'ATTAleckSansMd')
            .attr('fill', 'white')
            .text(totalValue);

        g.append('text')
            .attr('text-anchor', 'middle')
            .attr('dy', '1.5em')
            .attr('font-size', '18px')
            .attr('fill', 'white')
            .text('Total');

    }, [data, totalValue]);

    return <svg ref={ref}></svg>;
};

export default DonutChart;
