import axiosService from './axios.service';
import {UIBE_PREFIX} from '../utils/constants';
import { TrafficClass } from '../dtos/traffic-classes.dto';

class TrafficClassService {

    public getTrafficClasses = async (customerName: string): Promise<TrafficClass[]> => {
        let url = `${UIBE_PREFIX}/traffic-classes?customerName=${customerName}`;
        const res = await axiosService.get(url);
        return res?.data;
    }

    public saveTrafficClasses = async (customerName: string, trafficClasses: TrafficClass[]): Promise<TrafficClass[]> => {
        let url = `${UIBE_PREFIX}/traffic-classes?customerName=${customerName}`;
        const res = await axiosService.put(url, {traffic_classes_rules: trafficClasses});
        return res?.data;
    }
}

const trafficClassService = new TrafficClassService();
export default trafficClassService;
