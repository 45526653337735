import React, { useEffect, useRef, useState } from 'react';
import { Button, Select } from 'antd';
import CustomModal from '../../../../shared/custom-modal/custom-modal';
import DestinationTree from '../../destination-tree/destination-tree';
import { CheckedNode } from '../../../../../dtos/traffic-dest.dto';
import { useStores } from '../../../../store';
import { observer } from 'mobx-react-lite';
import { TrafficClass } from '../../../../../dtos/traffic-classes.dto';

export interface SelectDestinationProps {
    trafficClass: TrafficClass;
    disabled: boolean;
}
export const SelectDestination = observer<SelectDestinationProps>(({trafficClass, disabled}) => {
    const { trafficClassStore, useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [checkedDestinations, setCheckedDestinations] = useState<string[]>([]);
    const [apnOptions, setApnOptions] = useState([]);
    const [selectedApn, setSelectedApn] = useState<string>(null);
    const destinationTreeRef = useRef<{ getFinalCheckedDestinations: () => CheckedNode[] }>(null);

    useEffect(() => {
        setApnOptions(useCaseStore.apnAllList.map(apn => (
            {
                value: apn.name,
                label: apn.name
            })
        ));
    }, [useCaseStore.apnAllList]);

    const handleOk = () => {
        if (destinationTreeRef.current) {
            const finalCheckedDestinations = destinationTreeRef.current.getFinalCheckedDestinations()
                .map(dest => ({destination: dest.value, port: '*'}));

            const existingDestinations = trafficClass.rules.map(rule => ({
                destination: rule.ip || rule.dns,
                port: rule.port || '*'
            }));

            // Filter out already checked destinations and ports
            const newDestinations = finalCheckedDestinations.filter(dest =>
                !existingDestinations.some(existing =>
                    existing.destination === dest.destination && existing.port === dest.port
                )
            );

            trafficClassStore.addRulesToTrafficClass(trafficClass, newDestinations);
        }
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setSelectedApn(null);
    };

    const renderApnDropdown = () => {
        return (<>
            <div className="select-label">APN</div>
            <Select showSearch={true}
                    size={"large"}
                    popupClassName="dropdown-custom apn-selection"
                    options={apnOptions}
                    value={selectedApn}
                    onChange={setSelectedApn}
                    placeholder="Search APN..."
                    className="checkbox-select"
                    style={{width: '100%'}}
            />
        </>);
    };

    return (
        <>
            <Button className={'add-destinations secondary-btn'} type="primary"
                    onClick={() => setIsModalOpen(true)} disabled={disabled}>+ Destinations</Button>
            {isModalOpen && <CustomModal
                isOpen={isModalOpen}
                width={910}
                title={`Add Destinations to ${trafficClass.name}`}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Add"
                customClassName="traffic-classes-select-dest-modal"
                okButtonProps={{ disabled: !checkedDestinations.length}}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <div className="select-dest-modal">
                    <div className="select-dest-modal-header">
                        {renderApnDropdown()}
                    </div>
                    <div className="select-dest-modal-content">
                        <DestinationTree
                            ref={destinationTreeRef}
                            apn={selectedApn}
                            defaultCheckedDestinations={[]}
                            onCheckedUpdate={setCheckedDestinations}/>
                    </div>
                </div>
            </CustomModal>}
        </>
    );
});
