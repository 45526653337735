import React, { useEffect } from 'react';
import './app-content.scss';
import { useStores } from '../store';
import { observer } from 'mobx-react-lite';
import LoaderComponent from '../shared/loader/loader-component';
import ErrorModal from '../errors/error-modal';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router';
import { Dashboard } from './dashboard/dashboard';
import { Anomalies } from './anomalies/anomalies';
import { Insights } from './insights/insights';
import { Reports } from './insights/reports/reports';
import { Alerts } from './insights/alerts/alerts';
import { UseCaseSettings } from './use-cases-settings/use-cases-settings';
import { UseCaseManagement } from './use-cases-settings/use-case-management/use-case-management';
import { InsightItem } from './insights/insight-item/insight-item';
import { General } from './use-cases-settings/general/general';
import { ExceptionList } from './use-cases-settings/exception-list/exception-list';
import { Activity } from './insights/activity/activity';
import UseCase from './use-cases-settings/use-case-management/use-case/use-case';
import { AnomalyWidgetsWrapper } from './anomalies/anomaly-widgets-wrapper';
import { SecurityDashboard } from './security-dashboard/security-dashboard';
import IssueManagementTable from './issue-management-table/issue-management-table';
import { TrafficClasses } from './use-cases-settings/traffic-classes/traffic-classes';
import { InsightsTrafficClasses } from './insights/traffic-classes/insights-traffic-classes';

const AppContent = observer(() => {
    const { customerStore, uxStore, useCaseStore } = useStores();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathParts = location.pathname.split('/');
        const customerName = pathParts[1];
        customerStore.load(customerName).then(() => {
            if (customerName !== customerStore.selectedCustomer?.name) {
                // For incorrect customer name
                navigate(customerStore.selectedCustomer.name);
            }
        });
        useCaseStore.loadTemplate();
    }, []);

    useEffect(() => {
        if (customerStore.selectedCustomer) {
            useCaseStore.loadValues(customerStore.selectedCustomer.name);
        }
    }, [customerStore.selectedCustomer]);

    useEffect(() => {
        if (useCaseStore.isError || customerStore.isError) {
            navigate('error', { replace: true });
        }
    }, [useCaseStore.isError, customerStore.isError]);

    return (
        <div className="app-content">
            <Routes>
                <Route path="/" element={<Navigate to={customerStore.selectedCustomer?.name} />} />
                <Route path=":customerName/*">
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="security-dashboard" element={<SecurityDashboard />} />
                    <Route path="security-issue-management" element={<IssueManagementTable />} />
                    <Route path="anomalies" element={<AnomalyWidgetsWrapper />}>
                        <Route path=":useCaseName" element={<Anomalies />} />
                    </Route>
                    <Route path="settings" element={<UseCaseSettings />}>
                        <Route path="anomalies" element={<UseCaseManagement />} >
                            <Route path=":useCaseName" element={<UseCase />} />
                        </Route>
                        <Route path="exceptions" element={<ExceptionList />} />
                        <Route path="general" element={<General />} />
                        <Route path="traffic-classes" element={<TrafficClasses />} />
                        <Route path="*" element={<Navigate to={'anomalies'} replace={true} />} />
                    </Route>
                    <Route path="insights" element={<Insights />}>
                        <Route path="reports" element={<Reports />} />
                        <Route path="alerts" element={<Alerts/> } />
                        <Route path="activity" element={<Activity/> } />
                        <Route path="traffic-classes" element={<InsightsTrafficClasses />} />
                        <Route path="*" element={<Navigate to={'reports'} replace={true} />} />
                    </Route>
                    <Route path="report/:id" element={<InsightItem type={'report'} />} />
                    <Route path="alert/:id" element={<InsightItem type={'alert'} />} />
                    <Route path="*" element={<Navigate to={'dashboard'} replace={true} />} />
                </Route>
            </Routes>

            { uxStore.showLoader && <LoaderComponent /> }
            <ErrorModal />
        </div>
    );
});

export default AppContent;
