export enum DefaultTrafficClasses {
    STREAMING = 'Streaming',
    TELEMATICS = 'Telematics',
    NAVIGATION = 'Navigation',
    FOTA = 'FOTA',
    MANAGEMENT = 'Management'
}

export interface TrafficClassesRules {
    trafficClasses: TrafficClass[];
}

export interface TrafficClass {
    name: string;
    description: string;
    index: number;
    rules: TrafficClassRule[];
}

export interface TrafficClassRule {
    type: string;
    index: number;
    port: string;
    ip?: string;
    dns?: string;
}

export enum TrafficClassRuleType {
    IP_ADDRESS_AND_PORT = 'IP Address and Port',
    IP_ADDRESS_PREFIX_AND_PORT = 'IP Address Prefix and Port',
    DNS_FQDN_AND_PORT = 'DNS FQDN and Port',
    DNS_PREFIX_AND_PORT = 'DNS Prefix and Port',
    IP_ADDRESS_RANGE_AND_PORT = 'IP Address Range and Port'
}

export const DisplayedRuleType = {
    IP: [TrafficClassRuleType.IP_ADDRESS_AND_PORT, TrafficClassRuleType.IP_ADDRESS_PREFIX_AND_PORT, TrafficClassRuleType.IP_ADDRESS_RANGE_AND_PORT],
    URL: [TrafficClassRuleType.DNS_FQDN_AND_PORT, TrafficClassRuleType.DNS_PREFIX_AND_PORT]
}

export const parseRuleType = (ruleType: string) => {
    return Object.keys(DisplayedRuleType)
        .find(type => DisplayedRuleType[type].includes(ruleType));
};
