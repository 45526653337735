import React from 'react';
import './issue-categories.scss';
import BarChart from '../bar-chart/bar-chart';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';

const barData = [
    { date: 1696118400000, weaknesses: 10, threats: 20, attacks: 20 },
    { date: 1696204800000, weaknesses: 40, threats: 25, attacks: 15 },
    { date: 1696291200000, weaknesses: 35, threats: 30, attacks: 20 },
    { date: 1696377600000, weaknesses: 50, threats: 10, attacks: 55 },
    { date: 1696464000000, weaknesses: 10, threats: 40, attacks: 60 },
    { date: 1696550400000, weaknesses: 60, threats: 20, attacks: 25 },
];

const IssueCategories: React.FC = () => {
    return (
        <div className="issue-categories">
            <div className="title">Issue Categories</div>
            <BarChart data={barData} range={'days'} />
            <div className="legend">
                {CATEGORIES_CONFIG.map((config) => (
                    <div key={config.fieldName} className="legend-item">
                        <div className="legend-color"
                            style={{
                                background: `linear-gradient(to left, ${config.color} 0%, rgba(255, 255, 255, 0) 100%)`
                            }}
                        />
                        <span className="legend-text">{config.displayName}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default IssueCategories;
