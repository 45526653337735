import React from 'react';
import LineChart, { ColorType } from '../line-chart/line-chart';
import { ReactComponent as UpArrow } from '../../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as DownArrow } from '../../../../assets/svg/trend-down-arrow.svg';
import './trend-chart.scss';

interface TrendChartProps {
    title?: string;
    desc?: string;
    percent: number;
    trendDirection: 'up' | 'down';
    trendColor: 'red' | 'green';
    points: { x: number; y: number }[];
    lineColor: ColorType;
    width?: number;
    height?: number;
}

const TrendChart: React.FC<TrendChartProps> = ({ title = "", desc = "", percent, trendDirection, trendColor, points, lineColor, width, height }) => {
    const statusIcon = trendDirection === 'up' ? <UpArrow /> : <DownArrow />;

    return (
        <div className="trend-chart">
            <div className="trend-chart-info">
                <div className="trend-chart-title">{title}</div>
                <div className="trend-chart-desc">{desc}</div>
            </div>
            <div className="trend-chart-line">
                <div className={`trend-chart-status ${trendColor}`}>
                    <div className="trend-chart-percent">{percent}% {trendDirection}</div>
                    {statusIcon}
                </div>
                <LineChart points={points} colorType={lineColor} width={width} height={height} />
            </div>
        </div>
    );
};

export default TrendChart;
