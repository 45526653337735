import React, { useState } from 'react';
import TableIssue from '../table-issue/table-issue';
import './security-issues-tables.scss';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';
import { ReactComponent as CheckListIcon } from '../../../../assets/svg/checklist_96.svg';
import SecurityIssueDetails from '../../security-issue-details/security-issue-details';
import { IssueDetail } from '../../../../utils/model';
import { Link } from 'react-router-dom';

const SecurityIssuesTables: React.FC = () => {
    const [selectedIssue, setSelectedIssue] = useState<IssueDetail | null>(null);

    const data: IssueDetail[] = [
        {
            id: '1',
            title: 'SQL Injection',
            desc: 'SQL Injection detected with importance level high',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'high',
            deviceNumber: 12,
            recommendedAction: {
                id: 'action1',
                title: 'Mitigate SQL Injection',
                desc: 'Follow steps to mitigate SQL Injection.',
                status: 'high',
                apnName: 'apn1',
                timestamp: 1732198254000
            },
            apnList: ['apn1.example.com'],
            percent: 85.5,
            trend: 'up',
            deviceList: ['IMEI 310150123456789', 'IMEI 310150123456790'],
            category: 'attacks',
            points: []
        },
        {
            id: '2',
            title: 'Cross-Site Scripting',
            desc: 'Cross-Site Scripting detected with importance level medium',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'medium',
            deviceNumber: 20,
            recommendedAction: {
                id: 'action2',
                title: 'Mitigate XSS',
                desc: 'Follow steps to mitigate Cross-Site Scripting.',
                status: 'medium',
                apnName: 'apn3',
                timestamp: 1732198254000
            },
            apnList: ['apn3.example.com'],
            percent: 60.3,
            trend: 'down',
            deviceList: ['IMEI 310150123456791', 'IMEI 310150123456792'],
            category: 'attacks',
            points: []
        },
        {
            id: '3',
            title: 'DDoS Attack',
            desc: 'DDoS Attack detected with importance level high',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'high',
            deviceNumber: 41,
            recommendedAction: {
                id: 'action3',
                title: 'Mitigate DDoS',
                desc: 'Follow steps to mitigate DDoS Attack.',
                status: 'high',
                apnName: 'apn28',
                timestamp: 1732198254000
            },
            apnList: ['apn28.example.com'],
            percent: 75.2,
            trend: 'up',
            deviceList: ['IMEI 310150123456793', 'IMEI 310150123456794'],
            category: 'attacks',
            points: []
        },
        {
            id: '4',
            title: 'Phishing',
            desc: 'Phishing detected with importance level low',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'low',
            deviceNumber: 14,
            recommendedAction: {
                id: 'action4',
                title: 'Mitigate Phishing',
                desc: 'Follow steps to mitigate Phishing.',
                status: 'low',
                apnName: 'apn4',
                timestamp: 1732198254000
            },
            apnList: ['apn4.example.com'],
            percent: 45.7,
            trend: 'down',
            deviceList: ['IMEI 310150123456795', 'IMEI 310150123456796'],
            category: 'threats',
            points: []
        },
        {
            id: '5',
            title: 'Malware',
            desc: 'Malware detected with importance level medium',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'medium',
            deviceNumber: 25,
            recommendedAction: {
                id: 'action5',
                title: 'Mitigate Malware',
                desc: 'Follow steps to mitigate Malware.',
                status: 'medium',
                apnName: 'apn1',
                timestamp: 1732198254000
            },
            apnList: ['apn1.example.com'],
            percent: 55.5,
            trend: 'up',
            deviceList: ['IMEI 310150123456797', 'IMEI 310150123456798'],
            category: 'threats',
            points: []
        },
        {
            id: '6',
            title: 'Ransomware',
            desc: 'Ransomware detected with importance level high',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'high',
            deviceNumber: 123,
            recommendedAction: {
                id: 'action6',
                title: 'Mitigate Ransomware',
                desc: 'Follow steps to mitigate Ransomware.',
                status: 'high',
                apnName: 'apn15',
                timestamp: 1732198254000
            },
            apnList: ['apn15.example.com'],
            percent: 90.1,
            trend: 'down',
            deviceList: ['IMEI 310150123456799', 'IMEI 310150123456800'],
            category: 'threats',
            points: []
        },
        {
            id: '7',
            title: 'Buffer Overflow',
            desc: 'Buffer Overflow detected with importance level low',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'low',
            deviceNumber: 1,
            recommendedAction: {
                id: 'action7',
                title: 'Mitigate Buffer Overflow',
                desc: 'Follow steps to mitigate Buffer Overflow.',
                status: 'low',
                apnName: 'apn4',
                timestamp: 1732198254000
            },
            apnList: ['apn4.example.com'],
            percent: 30.0,
            trend: 'up',
            deviceList: ['IMEI 310150123456801'],
            category: 'weaknesses',
            points: []
        },
        {
            id: '8',
            title: 'Memory Leak',
            desc: 'Memory Leak detected with importance level medium',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'medium',
            deviceNumber: 23,
            recommendedAction: {
                id: 'action8',
                title: 'Mitigate Memory Leak',
                desc: 'Follow steps to mitigate Memory Leak.',
                status: 'medium',
                apnName: 'apn6',
                timestamp: 1732198254000
            },
            apnList: ['apn6.example.com'],
            percent: 50.5,
            trend: 'down',
            deviceList: ['IMEI 310150123456802', 'IMEI 310150123456803'],
            category: 'weaknesses',
            points: []
        },
        {
            id: '9',
            title: 'Integer Overflow',
            desc: 'Integer Overflow detected with importance level high',
            timestamp: '2024-11-21T16:27:39.000Z',
            importance: 'high',
            deviceNumber: 123,
            recommendedAction: {
                id: 'action9',
                title: 'Mitigate Integer Overflow',
                desc: 'Follow steps to mitigate Integer Overflow.',
                status: 'high',
                apnName: 'apn2',
                timestamp: 1732198254000
            },
            apnList: ['apn2.example.com'],
            percent: 70.3,
            trend: 'up',
            deviceList: ['IMEI 310150123456804', 'IMEI 310150123456805'],
            category: 'weaknesses',
            points: []
        }
    ];

    const totalIssues = 1240;
    const highImportanceIssues = 23;

    const dataWidget = { data, totalIssues, highImportanceIssues, total: { attacks: 30, threats: 28, weaknesses: 12 } };

    const handleRowClick = (row: IssueDetail) => {
        setSelectedIssue(row);
    };

    return <>
        <div className="security-issues-tables">
            <div className="security-issues-tables-header">
                <div>
                    <div className="security-issues-tables-title">Security Issues</div>
                    <Link className="security-view-all" to="../security-issue-management">View All</Link>
                </div>
                <div className="security-issues-tables-stats">
                    {dataWidget.highImportanceIssues > 0 && <div className="high">
                        <span>High Importance</span>
                        <span>{dataWidget.highImportanceIssues.toLocaleString('en-US')}</span>
                    </div>}
                    <div className="total">
                        <span>Total</span>
                        <span>{dataWidget.totalIssues.toLocaleString('en-US')}</span>
                    </div>
                </div>
            </div>
            {dataWidget.totalIssues === 0 ? (
                <div className="empty-state-total">
                    <CheckListIcon />
                    <div className="empty-state-title">No Security Issues Detected</div>
                    <div className="empty-state-desc">Great news! Your system is currently free of any security issues.</div>
                    <div className="empty-state-info">Our continuous monitoring ensures that your data and operations remain safe and secure.</div>
                </div>
            ) : (
                <div className="tables-issues-wrapper">
                    {CATEGORIES_CONFIG.map(category => (
                        <TableIssue
                            key={category.fieldName}
                            categoryKey={category.fieldName}
                            total={dataWidget.total[category.fieldName]}
                            categoryName={category.displayName}
                            icon={<category.icon />}
                            data={data.filter(item => item.category === category.fieldName)}
                            color={category.color}
                            onRowClick={handleRowClick}
                        />
                    ))}
                </div>
            )}
        </div>
        {selectedIssue && (
            <SecurityIssueDetails issue={selectedIssue} onClose={() => setSelectedIssue(null)} />
        )}
    </>;
};

export default SecurityIssuesTables;
