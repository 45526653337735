import React, { useEffect } from 'react';
import ReactRouterPrompt from 'react-router-prompt';
import CustomModal from '../custom-modal/custom-modal';
import { observer } from 'mobx-react-lite';

interface RouterPromptProps {
    isDirty: boolean;
    restoreData: () => void;
    getPath?: (pathname: string) => string;
}

export const RouterPrompt = observer<RouterPromptProps>(({isDirty, restoreData, getPath}) => {

    useEffect(() => {
        const handler = (event: BeforeUnloadEvent) => {
            if (isDirty) {
                event.preventDefault();
                event.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', handler);
        return () => {
            window.removeEventListener('beforeunload', handler);
        };
    }, []);

    const getBlockerFunction = () => {
        return (locationData: any) => {
            if (locationData?.currentLocation?.pathname && locationData?.nextLocation?.pathname) {
                const currentPathPrefix = getPath?.(locationData.currentLocation.pathname) ?? locationData.currentLocation.pathname;
                const nextPathPrefix = getPath?.(locationData.nextLocation.pathname) ?? locationData.nextLocation.pathname;
                if (currentPathPrefix === nextPathPrefix) {
                    return false;
                }
            }
            return isDirty;
        };
    };

    return (
        <div className='modal-container'>
            <ReactRouterPrompt when={getBlockerFunction()}>
                {({isActive, onConfirm, onCancel}) =>
                    <CustomModal
                        isOpen={isActive}
                        width={600}
                        title="Discard changes?"
                        onOk={() => {
                            restoreData();
                            onConfirm();
                        }}
                        onCancel={onCancel}
                        cancelButtonProps={{className: 'secondary-btn'}}
                        okText="Continue">
                        <br/>All changes will be lost, are you sure you want to continue?<br/><br/><br/>
                    </CustomModal>
                }
            </ReactRouterPrompt>
        </div>
    );
});