import { observer } from 'mobx-react-lite';
import React from 'react';
import { ColorType } from './line-chart/line-chart';
import TrendChart from './trend-chart/trend-chart';
import SecurityIssueImportance from './security-issue-importance/security-issue-importance';
import IssueCategories from './issue-categories/issue-categories';
import { ReactComponent as SyncIcon } from '../../../assets/svg/sync.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svg/information-circle_96.svg';
import { ReactComponent as DocIcon} from '../../../assets/svg/file_96.svg';
import { ReactComponent as GridIcon } from '../../../assets/svg/grid_96.svg';

import './security-dashboard.scss';
import HelpItem from './help-item/help-item';
import { HELP_INFO_DESC } from './security-dashboard.constant';
import Footer from './footer/footer';
import SecurityIssuesTables from './security-issues-tables/security-issues-tables';
import RecommendedActionList from './recommended-action-list/recommended-action-list';

export const SecurityDashboard = observer(() => {

    const refresh = () => {
        console.log('refresh');
    };

    const points = [
        { x: 0, y: 0 },
        { x: 10, y: 20 },
        { x: 20, y: 30 },
        { x: 30, y: 40 },
        { x: 40, y: 20 },
        { x: 50, y: 60 },
        { x: 60, y: 70 },
        { x: 70, y: 50 },
        { x: 80, y: 80 },
        { x: 90, y: 90 },
        { x: 100, y: 100 },
    ];

    return <div className="security-dashboard">
        <div className="security-dashboard-header">
            <div className="security-dashboard-header-title">Security dashboard</div>
            <div className="security-dashboard-header-action">
                <div className="security-dashboard-date-filter"></div>
                <button className="security-dashboard-refresh" onClick={refresh}>
                    <SyncIcon />
                </button>
            </div>
        </div>
        <div className="security-dashboard-content">
            <div className="security-dashboard-top row">
                <div className="box">
                    <TrendChart title={"5,042"} desc={"Total protected devices"} percent={20}
                                trendDirection={"down"} trendColor={"red"}
                                points={points} lineColor={ColorType.BLUE}/>
                </div>
                <div className="box">
                    <TrendChart title={"76"} desc={"Devices at risk"} percent={5}
                                trendDirection={"up"} trendColor={"red"}
                                points={points} lineColor={ColorType.RED}/>
                </div>
                <div className="box">
                    <TrendChart title={"124"} desc={"Total security issues"} percent={20.8}
                                trendDirection={"down"} trendColor={"green"}
                                points={points} lineColor={ColorType.ORANGE}/>
                </div>
            </div>
            <div className="security-dashboard-middle row">
                <div className="box">
                    <SecurityIssueImportance />
                </div>
                <div className="box">
                    <IssueCategories />
                </div>
            </div>
            <div className="security-dashboard-bottom row">
                <div className="box"><SecurityIssuesTables /></div>
                <div className="box"><RecommendedActionList /></div>
            </div>
            <div className="security-dashboard-help-area row">
                <div className="box">
                    <HelpItem
                        title="Help center"
                        description={HELP_INFO_DESC}
                        IconComponent={InfoIcon}
                    />
                </div>
                <div className="box">
                    <HelpItem
                        title="Documentation"
                        description={HELP_INFO_DESC}
                        IconComponent={DocIcon}
                    />
                </div>
                <div className="box">
                    <HelpItem
                        title="Tutorials"
                        description={HELP_INFO_DESC}
                        IconComponent={GridIcon}
                    />
                </div>
            </div>
        </div>
        <div className="security-dashboard-footer">
            <Footer />
        </div>
    </div>;
});