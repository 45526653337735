import React from 'react';
import moment from 'moment';
import './recommended-action.scss';
import { StatusColors } from '../security-dashboard.constant';
import { Action } from '../../../../utils/model';

interface RecommendedActionProps {
    action: Action;
}

const RecommendedAction: React.FC<RecommendedActionProps> = ({ action }) => {
    return (
        <div key={action.id} className="recommended-action-item">
            <div className="item-header">
                {action.status && (
                    <div
                        className="recommended-status-indicator"
                        style={{backgroundColor: StatusColors[action.status].color}}
                    ></div>
                )}
                <div className="title">{action.title}</div>
            </div>
            <div className="item-details">
                <div className="source"><span>Source:</span>{action.apnName}</div>
                <div className="timestamp">
                    {moment(action.timestamp).format('MMMM D, YYYY, h:mm A z')}
                </div>
            </div>
        </div>
    );
};

export default RecommendedAction;
