import { observer } from 'mobx-react-lite';
import CustomModal from '../../../../shared/custom-modal/custom-modal';
import React, { useEffect, useState } from 'react';
import { Button, Input } from 'antd';
import { TrafficClass } from '../../../../../dtos/traffic-classes.dto';
import { ReactComponent as PencilIcon } from '../../../../../assets/svg/pencil.svg';
import { useStores } from '../../../../store';
import './add-edit-class.scss';
import TextArea from 'antd/es/input/TextArea';

interface AddEditClassProps {
    mode: 'add' | 'edit';
    trafficClass?: TrafficClass;
}

const AddEditClass = observer<AddEditClassProps>(({mode, trafficClass}) => {

    const { trafficClassStore } = useStores();

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [className, setClassName] = useState<string>();
    const [classDescription, setClassDescription] = useState<string>();

    const saveClassName = () => {
        if (mode === 'add') {
            trafficClassStore.addClass(className, classDescription);
        } else if (mode === 'edit') {
            trafficClassStore.editClass(trafficClass, className, classDescription);
        }
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (trafficClass) {
            setClassName(trafficClass.name);
            setClassDescription(trafficClass.description);
        }
    }, [trafficClass]);

    return (<>
        {mode === 'add' && <Button className="add-class secondary-btn" type="primary"
                                   onClick={() => setIsModalOpen(true)}>Add class</Button>}
        {mode === 'edit' && <Button className='edit-class' onClick={() => setIsModalOpen(true)}>
            <PencilIcon />
        </Button>}
        <CustomModal
            isOpen={isModalOpen}
            width="540px"
            title={mode === 'add' ? 'Add new class' : 'Edit class'}
            customClassName={'add-edit-class'}
            okText={mode === 'add' ? "Add" : "Apply"}
            onOk={saveClassName}
            okButtonProps={!className}
            cancelButtonProps={{className: 'secondary-btn'}}
            onCancel={() => setIsModalOpen(false)}>
            <>
                <div className="textarea-wrapper">
                    <div className="select-label">Class name</div>
                    <Input
                        className={`input class-name-input`}
                        placeholder={'Enter class name'}
                        value={className}
                        maxLength={50}
                        onChange={(e) => setClassName(e.target.value)}
                    />
                </div>
                <div className="textarea-wrapper">
                    <div className="select-label">Class description</div>
                    <TextArea
                        size={"large"}
                        className={`textarea-input class-description-input`}
                        placeholder={'Enter description here...'}
                        value={classDescription}
                        maxLength={250}
                        onChange={(e) => setClassDescription(e.target.value)}
                    />
                </div>
            </>
        </CustomModal>
    </>);
});

export default AddEditClass;