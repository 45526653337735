import { observer } from 'mobx-react-lite';
import { useStores } from '../../../store';
import React, { useCallback, useEffect, useRef } from 'react';
import { SettingsSubMenu } from '../../../../utils/enums';
import { Button, message } from 'antd';
import './traffic-classes.scss';
import { TrafficClassRules } from './traffic-class-rules/traffic-class-rules';
import { TrafficClass } from '../../../../dtos/traffic-classes.dto';
import AddEditClass from './traffic-class-actions/add-edit-class';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from 'react-beautiful-dnd';
import { TrafficClassHeader } from './traffic-class-header';
import { RouterPrompt } from '../../../shared/router-prompt/router-prompt';

export const TrafficClasses = observer(() => {
    const {trafficClassStore, useCaseStore, customerStore} = useStores();
    const trafficClassRefs = useRef({});

    useEffect(() => {
        useCaseStore.setActiveSubMenu(SettingsSubMenu.TRAFFIC_CLASSES);
        if (customerStore.selectedCustomer) {
            trafficClassStore.load();
        }
    }, [trafficClassStore, customerStore.selectedCustomer]);

    const saveTrafficClasses = () => {
        trafficClassStore.save().then(() =>
            message.success('Update is successful!')
        ).catch((e) => {
            console.error('Error', e);
        });
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        trafficClassStore.reorderClasses(result.source.index, result.destination.index);
    };

    const isCollapsed = useCallback((className: string) =>
        trafficClassStore.collapsedClasses[className]
    , [trafficClassStore.collapsedClasses]);

    const getTrafficClassSection = (tClass: TrafficClass, provided: DraggableProvided) => {
        return <div
            className={`traffic-class-group ${isCollapsed(tClass.name) ? 'collapsed' : 'expanded'}`}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <TrafficClassHeader tClass={tClass} provided={provided} trafficClassRefs={trafficClassRefs}/>
            <div className="traffic-class-content"
                 ref={(el) => trafficClassRefs.current[tClass.name] = el}>
                <div className="traffic-class-description">{tClass.description}</div>
                <TrafficClassRules trafficClass={tClass}/>
            </div>
        </div>;
    };

    return (
        <div className="traffic-classes">
            <div className='traffic-classes-header'>
                <div className='traffic-classes-sub-title'>
                    <span>Traffic classes</span>
                </div>
                <div className='action-wrapper'>
                    {!trafficClassStore.isAllCollapsed &&
                        <Button className="collapse-all-button" onClick={() => trafficClassStore.collapseAll()}>Collapse All</Button>}
                    <AddEditClass mode={'add'}/>
                    <Button disabled={!trafficClassStore.isDirty} className={'save'} type="primary"
                            onClick={saveTrafficClasses}>Save</Button>
                </div>
            </div>

            {trafficClassStore.data && <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="traffic-classes-list">
                    {(provided) => (
                        <div className="traffic-classes-content" {...provided.droppableProps} ref={provided.innerRef}>
                            {trafficClassStore.data?.map((tClass, index) => (
                                <Draggable key={tClass.index} draggableId={tClass.index.toString()} index={index}>
                                    {(provided) => getTrafficClassSection(tClass, provided)}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>}

            <RouterPrompt isDirty={trafficClassStore.isDirty} restoreData={() =>  trafficClassStore.load()} />
        </div>
    );
});