import CustomModal from '../../../../shared/custom-modal/custom-modal';
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TrafficClass } from '../../../../../dtos/traffic-classes.dto';
import { useStores } from '../../../../store';
import { Button } from 'antd';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/delete.svg';

interface DeleteClassModalProps {
    trafficClass: TrafficClass;
}

export const DeleteClassModal: React.FunctionComponent<DeleteClassModalProps> = observer(({trafficClass}) => {

    const {trafficClassStore} = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        trafficClassStore.removeClass(trafficClass);
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button className='delete-class' onClick={() => setIsModalOpen(true)}>
                <DeleteIcon/>
            </Button>
            <CustomModal
                isOpen={isModalOpen}
                width={540}
                title="Are you sure you want to delete this traffic class"
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes, delete"
                cancelButtonProps={{className: 'secondary-btn'}}
                okButtonProps={{className: 'warning-btn'}}>
                <div>Please confirm your action, as once '{trafficClass.name}' is deleted, it cannot be retrieved.</div>
            </CustomModal>
        </>
    );
});
