import React from 'react';
import { Button, Checkbox } from 'antd';

export const FilterGroup: React.FC<{
    title: string;
    options: string[];
    selectedValues: string[];
    onChange: (values: string[]) => void;
}> = ({ title, options, selectedValues, onChange }) => (
    <>
        <div className="filter-group-header">
            <div className="filter-group-title">{title}</div>
            <Button
                className="any-button"
                onClick={() => onChange(options)}>
                Any
            </Button>
        </div>
        <Checkbox.Group
            options={options}
            value={selectedValues}
            onChange={(values) => onChange(values)}>
        </Checkbox.Group>
    </>

);