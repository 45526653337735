import AddEditTrafficDestination from './traffic-destination/add-edit-traffic-destination';
import { MAX_TRAFFIC_RULES } from '../../../../utils/constants';
import { SelectDestination } from './traffic-destination/select-destination';
import AddEditClass from './traffic-class-actions/add-edit-class';
import { DeleteClassModal } from './traffic-class-actions/delete-class-modal';
import { Button } from 'antd';
import React, { MutableRefObject } from 'react';
import { useStores } from '../../../store';
import { DefaultTrafficClasses, TrafficClass } from '../../../../dtos/traffic-classes.dto';
import { ReactComponent as CollapseIcon } from '../../../../assets/svg/chevron-down-circle.svg';
import { ReactComponent as VideoGalleryIcon } from '../../../../assets/svg/video-gallery.svg';
import { ReactComponent as MapIcon } from '../../../../assets/svg/map.svg';
import { ReactComponent as ConnectedVehicleIcon } from '../../../../assets/svg/connected-vehicle.svg';
import { ReactComponent as MobileWorkplaceIcon } from '../../../../assets/svg/mobile-workplace.svg';
import { ReactComponent as OperationsIcon } from '../../../../assets/svg/operations.svg';
import { ReactComponent as CollectionsIcon } from '../../../../assets/svg/collections.svg';
import { DraggableProvided } from 'react-beautiful-dnd';
import { observer } from 'mobx-react-lite';

const TrafficClassesIcons = {
    [DefaultTrafficClasses.STREAMING]: <VideoGalleryIcon/>,
    [DefaultTrafficClasses.NAVIGATION]: <MapIcon/>,
    [DefaultTrafficClasses.TELEMATICS]: <ConnectedVehicleIcon/>,
    [DefaultTrafficClasses.FOTA]: <MobileWorkplaceIcon/>,
    [DefaultTrafficClasses.MANAGEMENT]: <OperationsIcon/>,
};

interface TrafficClassHeaderParams {
    tClass: TrafficClass;
    provided: DraggableProvided;
    trafficClassRefs: MutableRefObject<{}>;
}

export const TrafficClassHeader = observer<TrafficClassHeaderParams>(({tClass, provided, trafficClassRefs}) => {
    const { trafficClassStore } = useStores();

    const isDefaultClass = (className) => {
        return Object.values(DefaultTrafficClasses).includes(className);
    };

    const handleToggleCollapseExpand = (className: string) => {
        trafficClassStore.toggleCollapseExpand(className);
        if (!trafficClassStore.collapsedClasses[className]) {
            trafficClassRefs.current[className]?.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    const addDestinationActions = (
        <div className="traffic-class-actions">
            <AddEditTrafficDestination trafficClass={tClass}
                                       mode={'add'}
                                       disabled={trafficClassStore.allRulesCount >= MAX_TRAFFIC_RULES}/>
            <SelectDestination trafficClass={tClass}
                               disabled={trafficClassStore.allRulesCount >= MAX_TRAFFIC_RULES}/>
        </div>
    );

    const customTrafficClassActions = (
        <>
            <AddEditClass mode={'edit'} trafficClass={tClass}/>
            <DeleteClassModal trafficClass={tClass}/>
        </>
    );

    return (
        <div className="traffic-class-header">
            <div className="traffic-class-name">
                {trafficClassStore.isAllCollapsed && <span {...provided.dragHandleProps}
                                                           className="drag-handle-icon">⋮⋮</span>}

                <i className="traffic-class-icon">{TrafficClassesIcons[tClass.name] || <CollectionsIcon/>}</i>
                {tClass.name} {tClass.rules.length > 0 && <>({tClass.rules.length})</>}
                {!isDefaultClass(tClass.name) && customTrafficClassActions}
            </div>
            <div className="traffic-class-actions-wrapper">
                {addDestinationActions}
                <Button className="collapse-icon" onClick={() => handleToggleCollapseExpand(tClass.name)}>
                    <CollapseIcon/>
                </Button>
            </div>
        </div>
    );
});