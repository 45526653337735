import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { ReactComponent as UpArrow } from '../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/svg/trend-down-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/x-blue.svg';
import { ReactComponent as ExpandIcon } from '../../../assets/svg/chevron-up.svg';
import { ReactComponent as SearchIcon } from '../../../assets/svg/searchbox.svg';
import { StatusColors } from '../security-dashboard/security-dashboard.constant';
import RecommendedAction from '../security-dashboard/recommended-action/recommended-action';
import { IssueDetail } from '../../../utils/model';
import './security-issue-details.scss';
import Speedometer from '../speedometer/speedometer';
import AffectedDevices from '../affected-devices/affected-devices';

interface SecurityIssueDetailsProps {
    issue: IssueDetail;
    onClose: () => void;
}

const SecurityIssueDetails: React.FC<SecurityIssueDetailsProps> = ({ issue, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDeviceList, setFilteredDeviceList] = useState<string[]>(issue.deviceList);
    const [isDeviceListCollapsed, setIsDeviceListCollapsed] = useState(false);
    const [isApnListCollapsed, setIsApnListCollapsed] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (issue) {
            const filtered = issue.deviceList.filter(device =>
                device.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredDeviceList(filtered);
        }
    }, [searchTerm, issue]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const { target } = event;
            let className = '';
            if (target instanceof HTMLElement) {
                className = target.className;
            }
            if (containerRef.current && !containerRef.current.contains(target as Node) && !className.includes('cell')) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const handleClose = () => {
        setIsClosing(true);
        setTimeout(() => {
            setIsClosing(false);
            onClose();
        }, 400);
    };

    return (
        <div className={`security-issue-details ${isClosing ? 'closing' : 'open'}`} ref={containerRef}>
            <div className="security-issue-header">
                <div className="security-issue-details-title">{issue.title}</div>
                <button onClick={handleClose}><CloseIcon /></button>
            </div>
            <div className="details">
                <div className="issue-details-time">{moment(issue.timestamp).format('MMMM D, YYYY, h:mm A z')}</div>
                <div className="issue-details-widgets">
                    <div className="importance-status widget" style={{color: StatusColors[issue.importance].color}}>
                        <Speedometer status={issue.importance}/>
                    </div>
                    <div className="issue-details-trend widget">
                        <AffectedDevices deviceNumber={issue.deviceNumber} percent={issue.percent}
                                         trendDirection={issue.trend as 'up' | 'down'} points={issue.points}/>
                    </div>
                </div>
                <div className="issue-details-desc">{issue.desc}</div>
                <div className="issue-details-recommended-action">
                    <div className="issue-details-recommended-title">Recommended Action</div>
                    <RecommendedAction action={issue.recommendedAction}/>
                </div>
                <div className="issue-details-affected-apns">
                    <div className="issue-details-affected-header">
                        <div className="section-header-title"> Affected APNs<span>{issue.apnList.length}</span></div>
                        <button onClick={() => setIsApnListCollapsed(!isApnListCollapsed)}>
                            <ExpandIcon className={isApnListCollapsed ? 'collapsed' : 'expanded'}/>
                        </button>
                    </div>
                    <div className={`affected-apns-list ${isApnListCollapsed ? 'collapsed' : ''}`}>
                        {issue.apnList.map(apn => (
                            <div key={apn}>{apn}</div>
                        ))}
                    </div>
                </div>
                <div className="device-section">
                    <div className="device-section-header">
                        <div className="section-header-title">Devices<span>{filteredDeviceList.length}</span></div>
                        <div className="section-header-info">
                            <span>{issue.percent}% {issue.trend}</span>
                            <span className={issue.trend}>{issue.trend === 'up' ? <UpArrow/> : <DownArrow/>}</span>
                            <button onClick={() => setIsDeviceListCollapsed(!isDeviceListCollapsed)}>
                                <ExpandIcon className={isDeviceListCollapsed ? 'collapsed' : 'expanded'}/>
                            </button>
                        </div>
                    </div>
                    <div className={`device-list-wrapper ${isDeviceListCollapsed ? 'collapsed' : ''}`}>
                        <div className="search-box">
                            <input
                                type="text"
                                placeholder="Search devices by IMEI...."
                                value={searchTerm}
                                onChange={e => setSearchTerm(e.target.value)}
                            />
                            <SearchIcon className="search-icon"/>
                        </div>
                        <div className="device-list">
                            {filteredDeviceList.map(device => (
                                <div key={device} className="device-item">{device}</div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SecurityIssueDetails;
