import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import moment from 'moment';
import './bar-chart.scss';
import { CATEGORIES_CONFIG } from '../security-dashboard.constant';

interface BarChartProps {
    data: Array<{
        date: number; // Assuming date is a timestamp
        weaknesses: number;
        threats: number;
        attacks: number;
    }>;
    range: 'hours' | 'days';
}

const BarChart: React.FC<BarChartProps> = ({ data, range }) => {
    const ref = useRef();
    const chartWidth = 570;
    const chartHeight = 250;

    useEffect(() => {
        const svg = d3.select(ref.current);
        const margin = { top: 20, right: 10, bottom: 20, left: 10 };
        const width = chartWidth - margin.left - margin.right;
        const height = chartHeight - margin.top - margin.bottom;

        svg.attr('viewBox', `0 0 ${chartWidth} ${chartHeight}`)
            .attr('preserveAspectRatio', 'xMidYMid meet');

        const defs = svg.append('defs');

        // Define gradients
        CATEGORIES_CONFIG.forEach(config => {
            const linearGradient = defs.append('linearGradient')
                .attr('id', config.gradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '0%')
                .attr('y2', '100%');

            linearGradient.append('stop')
                .attr('offset', '0%')
                .attr('stop-color', config.color)
                .attr('stop-opacity', 1);

            linearGradient.append('stop')
                .attr('offset', '100%')
                .attr('stop-color', config.color)
                .attr('stop-opacity', 0);
        });

        const g = svg.append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        const x0 = d3.scaleBand()
            .rangeRound([0, width])
            .paddingInner(0.1);

        const y = d3.scaleLinear()
            .rangeRound([height, 0]);

        const color = d3.scaleOrdinal()
            .domain(CATEGORIES_CONFIG.map(config => config.displayName))
            .range(CATEGORIES_CONFIG.map(config => `url(#${config.gradientId})`));

        const categories = CATEGORIES_CONFIG.map(config => config.fieldName);
        const maxIssues = d3.max(data, d => d3.max(categories, key => d[key]));
        const roundedMax = Math.ceil(maxIssues / 5) * 5; // Round up to the nearest multiple of 5

        x0.domain(data.map(d => d.date));
        y.domain([0, roundedMax]);

        g.append('g')
            .selectAll('g')
            .data(data)
            .enter().append('g')
            .attr('transform', d => `translate(${x0(d.date)},0)`)
            .selectAll('rect')
            .data(d => categories.map((key, i) => ({ key, value: d[key], index: i })))
            .enter().append('rect')
            .attr('x', d => d.index * (15 + 8)) // 15px width + 8px gap
            .attr('y', d => y(d.value))
            .attr('width', 15) // Set the width to 15px
            .attr('height', d => height - y(d.value))
            .attr('fill', d => color(d.key))
            .attr('rx', 10) // Border radius top left and top right
            .attr('ry', 10);

        g.append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${height})`)
            .call(d3.axisBottom(x0).tickFormat((d, i) => {
                const date = moment(d);
                return range === 'days' ? date.format('MMM D') : date.format('HH:mm');
            }))
            .selectAll('text')
            .attr('fill', 'white')
            .style('font-size', '14px')
            .style('font-family', 'ATTAleckSansMd');

        g.append('g')
            .attr('class', 'axis axis--y')
            .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('d'))) // Display only numbers, 5 ticks
            .selectAll('text')
            .attr('fill', 'white')
            .style('font-size', '14px')
            .style('font-family', 'ATTAleckSansMd');

        // Remove the y-axis line
        g.select('.axis--y path').remove();
        // Remove the x-axis line
        g.select('.axis--x path').remove();
        g.selectAll('.axis line')
            .attr('stroke', 'rgba(255, 255, 255, 0.1)'); // Light grid lines

        // Update the x-axis tick lines
        g.selectAll('.axis--x line')
            .attr('y2', 19)
            .attr('stroke', 'white')
            .attr('transform', 'translate(0, -15)'); // Move the tick lines 15px above

        // Add horizontal grid lines
        g.selectAll('.grid')
            .data(y.ticks(5))
            .enter().append('line')
            .attr('class', 'grid')
            .attr('x1', 0)
            .attr('x2', width)
            .attr('y1', y)
            .attr('y2', y)
            .attr('stroke', 'rgba(255, 255, 255, 0.1)')
            .attr('stroke-width', 1);

        // Center x-axis ticks
        svg.selectAll(".axis--x .tick")
            .attr("transform", (d) => {
                return "translate(" + (x0(d) + x0.bandwidth() / 2 - 11) + ",0)";
            });
    }, [data, range]);

    return <svg ref={ref} style={{ width: '100%', height: `${chartHeight}px` }}></svg>;
};

export default BarChart;
