import React from 'react';
import './footer.scss';
import { ReactComponent as Logo } from '../../../../assets/svg/att-logo.svg';
import { FOOTER_TEXT } from '../security-dashboard.constant';

const Footer: React.FC = () => {
    const footerItems = [
        { id: 'privacy-center', text: "Your privacy center" },
        { id: 'terms-of-use', text: "Terms of use" },
        { id: 'accessibility', text: "Accessibility" },
        { id: 'privacy-choices', text: "Your privacy choices" },
    ];

    return (
        <div className="footer">
            <div className="footer-items">
                {footerItems.map((item) => (
                    <div key={item.id} className="footer-item">{item.text}</div>
                ))}
            </div>
            <div className="footer-row">
                <div className="footer-text">{FOOTER_TEXT}</div>
                <div className="footer-logo">
                    <Logo />
                </div>
            </div>
        </div>
    );
};

export default Footer;
