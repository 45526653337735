import React, { useMemo, useState, useEffect } from 'react';
import './issue-management-table.scss';
import { IssueDetail } from '../../../utils/model';
import { ReactComponent as ArrowUp } from '../../../assets/svg/arrows-vertical.svg';
import { ReactComponent as TrendUpArrow } from '../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as TrendDownArrow } from '../../../assets/svg/trend-down-arrow.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-left.svg';
import { ReactComponent as Chevron } from '../../../assets/svg/chevron-grey.svg';
import { StatusColors } from '../security-dashboard/security-dashboard.constant';
import { Button, Popover } from 'antd';
import { FilterGroup } from './filter-group';
import SecurityIssueDetails from '../security-issue-details/security-issue-details';
import { useLocation } from 'react-router';
import Footer from '../security-dashboard/footer/footer';

const generateIssues = (numIssues: number): IssueDetail[] => {
    const issues: IssueDetail[] = [];
    const categories = ['attacks', 'threats', 'weaknesses'];
    const importance = ['low', 'medium', 'high'];
    const trends = ['up', 'down'];

    for (let i = 1; i <= numIssues; i++) {
        issues.push({
            id: i.toString(),
            title: `Issue ${i}`,
            desc: `Description for issue ${i}`,
            timestamp: new Date().toISOString(),
            importance: importance[Math.floor(Math.random() * importance.length)] as 'low' | 'medium' | 'high',
            deviceNumber: Math.floor(Math.random() * 100),
            recommendedAction: {
                id: '',
                title: '',
                desc: '',
                timestamp: 0,
                apnName: ''
            },
            apnList: [`apn${i}`],
            percent: Math.floor(Math.random() * 100),
            trend: trends[Math.floor(Math.random() * trends.length)],
            deviceList: [`device${i}`],
            points: [
                { x: 0, y: 0 },
                { x: 10, y: 30 },
                { x: 20, y: 50 },
                { x: 30, y: 20 },
                { x: 40, y: 10 },
                { x: 50, y: 90 },
                { x: 60, y: 70 },
                { x: 70, y: 70 },
                { x: 80, y: 50 },
                { x: 90, y: 20 },
                { x: 100, y: 10 },
            ],
            category: categories[Math.floor(Math.random() * categories.length)] as 'attacks' | 'threats' | 'weaknesses',
        });
    }
    return issues;
};

const IssueManagementTable: React.FC = () => {
    const issues = useMemo(() => generateIssues(50), []);
    const [selectedIssue, setSelectedIssue] = useState<IssueDetail | null>(null);
    const [sortField, setSortField] = useState<keyof IssueDetail>('title');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [issuesPerPage, setIssuesPerPage] = useState(15); // Default value
    const [minHeight, setMinHeight] = useState(600);

    const defaultFilters = { importance: ['high', 'medium', 'low'], category: ['attacks', 'threats', 'weaknesses'] };
    const [filters, setFilters] = useState<{ importance: string[], category: string[] }>(defaultFilters);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const importance = queryParams.get('importance')?.split(',') || defaultFilters.importance;
        const category = queryParams.get('category')?.split(',') || defaultFilters.category;
        setFilters({ importance, category });
    }, [location.search]);

    useEffect(() => {
        const calculateIssuesPerPage = () => {
            const viewportHeight = window.innerHeight;
            const remainingHeight = viewportHeight - 360; // Subtract the height of other elements
            const rows = Math.floor(remainingHeight / 40);
            if (rows) {
                setMinHeight(rows * 41); // Include the border
                setIssuesPerPage(rows);
            }
        };

        calculateIssuesPerPage();
        window.addEventListener('resize', calculateIssuesPerPage);

        return () => window.removeEventListener('resize', calculateIssuesPerPage);
    }, []);

    const handleSort = (field: keyof IssueDetail) => {
        setSortOrder(sortField === field && sortOrder === 'asc' ? 'desc' : 'asc');
        setSortField(field);
    };

    const handleFilterChange = (key: keyof typeof filters, values: string[]) => {
        setFilters({ ...filters, [key]: values });
        setCurrentPage(1);
    };

    const onRowClick = (row: IssueDetail) => {
        setSelectedIssue(row);
    };

    const filteredIssues = useMemo(() => issues.filter(issue => {
        const importanceMatch = filters.importance.includes(issue.importance);
        const categoryMatch = filters.category.includes(issue.category);
        return importanceMatch && categoryMatch;
    }), [issues, filters]);

    const sortedIssues = useMemo(() => [...filteredIssues].sort((a, b) => {
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    }), [filteredIssues, sortField, sortOrder]);

    const currentIssues = useMemo(() => {
        const indexOfLastIssue = currentPage * issuesPerPage;
        return sortedIssues.slice(indexOfLastIssue - issuesPerPage, indexOfLastIssue);
    }, [sortedIssues, currentPage, issuesPerPage]);

    const headers = [
        { label: 'Use Case', field: 'title' },
        { label: 'Importance', field: 'importance' },
        { label: 'Category', field: 'category' },
        { label: 'Devices', field: 'deviceNumber' },
        { label: 'APN', field: 'apnList' },
    ];

    const filterContent = (
        <div>
            <FilterGroup
                title="Importance"
                options={['high', 'medium', 'low']}
                selectedValues={filters.importance}
                onChange={(values) => handleFilterChange('importance', values)}
            />
            <FilterGroup
                title="Type"
                options={['attacks', 'threats', 'weaknesses']}
                selectedValues={filters.category}
                onChange={(values) => handleFilterChange('category', values)}
            />
        </div>
    );

    return (
        <div className="issue-management-page">
            <div className="issue-management-header">
                <div className="title">Security Issues ({filteredIssues.length})</div>
                <div className="issue-management-actions">
                    <Popover content={filterContent} trigger="click" overlayClassName="filter-popup" arrow={false}>
                        <Button className="filter-button">
                            <span>Filter by</span><Chevron />
                        </Button>
                    </Popover>
                </div>
            </div>
            <div className="issue-management-table">
                <div className="table-header">
                    {headers.map(header => (
                        <div
                            key={header.field}
                            className="header-cell"
                            onClick={() => handleSort(header.field as keyof IssueDetail)}
                            onKeyDown={() => handleSort(header.field as keyof IssueDetail)}
                        >
                            {header.label}
                            {sortField === header.field &&
                                <ArrowUp className={`sort-icon ${sortOrder === 'asc' ? 'asc' : 'desc'}`}/>}
                        </div>
                    ))}
                </div>
                <div className="table-body" style={{ minHeight: `${minHeight}px` }}>
                    {currentIssues.map(issue => (
                        <div className={`row ${issue.id === selectedIssue?.id ? 'active' : ''}`} key={issue.id}
                             onClick={() => onRowClick(issue)}
                             onKeyDown={() => onRowClick(issue)}>
                            <div className="cell">{issue.title}</div>
                            <div className="cell importance">
                                <div className="importance-wrapper">
                                    <div className="importance" style={{
                                        color: StatusColors[issue.importance].color,
                                        borderColor: StatusColors[issue.importance].color}}>
                                        {issue.importance}
                                    </div>
                                </div>
                            </div>
                            <div className="cell">{issue.category}</div>
                            <div className={`cell devices ${issue.trend}`}>
                                <div className="device-number">{issue.deviceNumber.toLocaleString('en-US')}</div>
                                {issue.trend === 'up' ? <TrendUpArrow/> : <TrendDownArrow/>}
                            </div>
                            <div className="cell apn-number">{issue.apnList.length}</div>
                        </div>
                    ))}
                </div>
                <div className="pagination">
                    <button className="prev-next-btn" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>
                        <Arrow/><span>Prev</span>
                    </button>
                    <div className="pagination-number">
                        {Array.from({length: Math.ceil(sortedIssues.length / issuesPerPage)}, (_, i) => (
                            <button
                                key={i + 1}
                                onClick={() => setCurrentPage(i + 1)}
                                className={currentPage === i + 1 ? 'active' : ''}>
                                {i + 1}
                            </button>
                        ))}
                    </div>

                    <button className="prev-next-btn" onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === Math.ceil(sortedIssues.length / issuesPerPage)}>
                        <span>Next</span><Arrow/>
                    </button>
                </div>
            </div>
            <Footer />
            {selectedIssue && (
                <SecurityIssueDetails issue={selectedIssue} onClose={() => setSelectedIssue(null)} />
            )}
        </div>
    );
};

export default IssueManagementTable;