import { CheckedNode, TrafficDest, TreeNode } from '../../../../dtos/traffic-dest.dto';
import { validateIP } from '../../../../utils/util';

const calculateTotalSum = (data: TrafficDest[]): number => {
    return data.reduce((sum, item) => sum + item.totalMb, 0);
};
const getSubnet = (ipAddress: string, subnetMask: string): string => {
    const ipParts = ipAddress.split('.').map(Number);
    const maskParts = subnetMask.split('.').map(Number);
    const subnetParts = ipParts.map((part, i) => part & maskParts[i]);
    return subnetParts.join('.');
};

const formatSubnetTitle = (subnet: string, subnetMask: string): string => {
    const maskParts = subnetMask.split('.').map(Number);
    const subnetParts = subnet.split('.');

    return subnetParts.map((part, i) => (maskParts[i] === 255 ? part : '*')).join('.');
};

const buildTreeNodes = (ips: TrafficDest[], subnetMasks: string[], totalSum: number): TreeNode[] => {
    if (subnetMasks.length === 0) {
        // Base case: No more subnet masks to process
        return ips.map((ipItem, index) => ({
            key: ipItem.hostName,
            trafficDest: ipItem,
            totalMb: ipItem.totalMb,
            percentage: (ipItem.totalMb / totalSum) * 100,
            type: 'IP'
        }));
    }

    const currentMask = subnetMasks[0];
    const remainingMasks = subnetMasks.slice(1);
    const subnetMap: { [key: string]: TrafficDest[] } = {};

    ips.forEach(ipItem => {
        const subnet = getSubnet(ipItem.hostName, currentMask);
        if (!subnetMap[subnet]) {
            subnetMap[subnet] = [];
        }
        subnetMap[subnet].push(ipItem);
    });

    return Object.keys(subnetMap).sort((a, b) => a.localeCompare(b)).map(subnet => {
        const type = 'IP';
        const children = buildTreeNodes(subnetMap[subnet], remainingMasks, totalSum);
        const totalMb = children.reduce((sum, child) => sum + (child.totalMb || 0), 0);
        if (children.length > 1) {
            return {
                key: formatSubnetTitle(subnet, currentMask),
                children,
                totalMb,
                percentage: (totalMb / totalSum) * 100,
                type
            };
        } else {
            return {
                ...children[0],
                totalMb,
                percentage: (totalMb / totalSum) * 100,
                type
            };
        }
    });
};

export const buildTreeData = (data: TrafficDest[], subnetMasks: string[] = ['255.255.0.0', '255.255.255.0', '255.255.255.255']): TreeNode[] => {
    const domainMap: { [key: string]: TrafficDest[] } = {};
    const ipList: TrafficDest[] = [];

    data.forEach(item => {
        if (validateIP(item.hostName)) {
            // It's an IP address
            ipList.push(item);
        } else {
            // It's a URL domain
            const domainParts = item.hostName.split('.');
            const mainDomain = domainParts.slice(-2).join('.'); // Get the main domain (e.g., test1.com)

            if (!domainMap[mainDomain]) {
                domainMap[mainDomain] = [];
            }
            domainMap[mainDomain].push(item);
        }
    });

    const totalSum = calculateTotalSum(data);

    const sortedMainDomains = Object.keys(domainMap).sort((a, b) => a.localeCompare(b));

    const treeData: TreeNode[] = [];

    // Process domains
    sortedMainDomains.forEach(mainDomain => {
        const type = 'URL';
        const subDomains = domainMap[mainDomain].toSorted((a, b) => a.hostName.localeCompare(b.hostName));
        const totalMb = subDomains.reduce((sum, subDomain) => sum + subDomain.totalMb, 0);
        if (subDomains.length > 1) {
            treeData.push({
                key: `*.${mainDomain}`,
                children: subDomains.map((subDomain, index) => ({
                    key: subDomain.hostName,
                    trafficDest: subDomain,
                    totalMb: subDomain.totalMb,
                    percentage: (subDomain.totalMb / totalSum) * 100,
                    type
                })),
                totalMb,
                percentage: (totalMb / totalSum) * 100,
                type
            });
        } else {
            const [subDomain] = subDomains;
            treeData.push({
                key: subDomain.hostName,
                trafficDest: subDomain,
                totalMb: subDomain.totalMb,
                percentage: (subDomain.totalMb / totalSum) * 100,
                type
            });
        }
    });

    // Process IPs
    if (ipList.length > 0) {
        treeData.push(...buildTreeNodes(ipList, subnetMasks, totalSum));
    }
    return treeData;
};

export const getFinalCheckedNodes = (treeData: TreeNode[], checkedKeys: string[]): CheckedNode[] => {
    const getParentNodes = (nodes: TreeNode[], checkedKeys: string[]): CheckedNode[] => {
        return nodes.reduce((acc, currentNode) => {
            if (checkedKeys.includes(currentNode.key)) {
                // If the node is checked
                if (!currentNode.children || currentNode.children.every(child => !checkedKeys.includes(child.key))) {
                    // If it has no children or all children are unchecked, add it
                    acc.push({ name: currentNode.type, value: currentNode.key });
                } else {
                    // Otherwise, skip adding its children
                    acc.push({ name: currentNode.type, value: currentNode.key });
                    acc = acc.filter(node => !currentNode.children.some(child => child.key === node.value));
                }
            } else if (currentNode.children) {
                // Recursively check children
                acc = acc.concat(getParentNodes(currentNode.children, checkedKeys));
            }
            return acc;
        }, [] as CheckedNode[]);
    };

    return getParentNodes(treeData, checkedKeys);
};

export const isWildcardMatch = (pattern: string, value: string): boolean => {
    const regex = new RegExp('^' + pattern.replace(/\*/g, '.*') + '$');
    return regex.test(value);
};

export const isIPInSubnet = (ip: string, subnetWithMask: string): boolean => {
    const [subnet, maskLengthStr] = subnetWithMask.split('/');
    const maskLength = parseInt(maskLengthStr, 10);
    if (!subnet || isNaN(maskLength)) return false;

    const ipParts = ip.split('.').map(Number);
    const subnetParts = subnet.split('.').map(Number);

    if (ipParts.length !== 4 || subnetParts.length !== 4) return false;

    const mask = (-1 << (32 - maskLength)) >>> 0;

    const ipInt = ipParts.reduce((sum, part, index) => sum + (part << ((3 - index) * 8)), 0) >>> 0;
    const subnetInt = subnetParts.reduce((sum, part, index) => sum + (part << ((3 - index) * 8)), 0) >>> 0;

    return (ipInt & mask) === (subnetInt & mask);
};

export const calculateTotalPercentage = (destList, trafficDests, totalTrafficMb) => {
    let totalMatchedMb = 0;

    const matchedTrafficDestinations = new Set();

    destList.forEach(dest => {
        trafficDests.forEach(trafficDest => {
            const isMatch = (
                dest.value === trafficDest.hostName ||
                isWildcardMatch(dest.value, trafficDest.hostName) ||
                isIPInSubnet(trafficDest.hostName, dest.value) ||
                trafficDest.ips?.some((ip: string) => isIPInSubnet(ip, dest.value))
            );

            if (isMatch && !matchedTrafficDestinations.has(trafficDest.hostName)) {
                totalMatchedMb += trafficDest.totalMb;
                matchedTrafficDestinations.add(trafficDest.hostName);
            }
        });
    });

    return (totalMatchedMb / totalTrafficMb || 0) * 100;
};