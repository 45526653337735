import React, { useMemo } from 'react';
import moment from 'moment';
import './security-issue-importance.scss';
import DonutChart from '../donut-chart/donut-chart';
import { StatusColors } from '../security-dashboard.constant';

const statuses = ['high', 'medium', 'low'];

const SecurityIssueImportance = () => {

    const data = [
        { value: 400, status: 'high', info: 'Part A', devices: 1000, date: '2024-01-01' },
        { value: 600, status: 'medium', info: 'Part B', devices: 2000, date: '2024-01-02' },
        { value: 1000, status: 'low', info: 'Part C', devices: 3000, date: '2024-01-03' },
    ];
    const apnName="apn1";
    const issuesCount=5;
    const issueStatus="high";
    const timestamp="2024-01-01T00:00:00Z";

    const getStatusCount = (status) => {
        return data.find(d => d.status === status).value;
    };

    const totalValue = useMemo(() =>
        data.reduce((acc, d) => acc + d.value, 0).toLocaleString('en-US')
    , [data]);

    return (
        <div className="security-issue-importance">
            <div className="security-title">Security Issue Importance</div>
            <div className="security-wrapper">
                <div className="security-content">
                    <div className="security-legend">
                        <div className="security-legend-title">Count</div>
                        <div className="security-legend-items">
                            {statuses.map(status => (
                                <div key={status} className="legend-item">
                                    <div className="legend-color" style={{backgroundColor: StatusColors[status].color}} />
                                    <div className="legend-count">{getStatusCount(status).toLocaleString('en-US')}</div>
                                    <div className="legend-text">{status}</div>
                                </div>
                            ))}
                        </div>
                        </div>
                        <div className="issue-details">
                            <div className="issue-count-msg">
                                <span className="issue-count-circle" style={{backgroundColor: StatusColors[issueStatus].color}} />
                                <span>{issuesCount} New {issueStatus} importance threats detected</span>
                            </div>
                            <div className="issue-source-msg"><span>Source:</span>{apnName}</div>
                            <div className="issue-datetime-msg">{moment(timestamp).format('MMMM D, YYYY, h:mm A z')}</div>
                        </div>
                    </div>
                    <div className="security-chart">
                        {totalValue ? <DonutChart data={data} totalValue={totalValue} /> : <div className="no-result">No issues found</div>}
                    </div>
                </div>
        </div>
    );
};

export default SecurityIssueImportance;
