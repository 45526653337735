import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TrafficClass, TrafficClassRule } from '../../../../../dtos/traffic-classes.dto';
import { ReactComponent as DeleteIcon } from '../../../../../assets/svg/delete.svg';
import './traffic-class-rules.scss';
import { useStores } from '../../../../store';
import AddEditTrafficDestination from '../traffic-destination/add-edit-traffic-destination';
import { Button } from 'antd';
import { DragDropContext, Droppable, Draggable, DropResult, DraggableProvided } from 'react-beautiful-dnd';

export interface ITrafficClassRulesProps {
    trafficClass: TrafficClass;
}

export const TrafficClassRules: FC<ITrafficClassRulesProps> = observer(({ trafficClass }) => {
    const { trafficClassStore } = useStores();

    const deleteRule = (trafficClass: TrafficClass, ruleIndex: number) => {
        trafficClassStore.removeRuleAndReorder(trafficClass, ruleIndex);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        trafficClassStore.reorderRules(trafficClass, result.source.index, result.destination.index);
    };

    const getRuleRow = (rule: TrafficClassRule, provided: DraggableProvided) => {
        return <div
            className='rule-row'
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <span {...provided.dragHandleProps} className="drag-handle-icon">⋮⋮</span>
            <div className='rule-cell'>{rule.ip || rule.dns}</div>
            <div className='rule-cell'>{rule.port}</div>
            <div className='action-cell'>
                <AddEditTrafficDestination trafficClass={trafficClass}
                                           rule={rule} mode={'edit'}/>
                <Button className='delete-cell'
                        onClick={() => deleteRule(trafficClass, rule.index)}>
                    <DeleteIcon/>
                </Button>
            </div>
        </div>;
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`droppable-${trafficClass.name}`}>
                {(provided) => (
                    <div className='traffic-class-rules-table' ref={provided.innerRef} {...provided.droppableProps}>
                        {trafficClass.rules.length ? <>
                            <div className='rules-table-header'>
                                <div className="drag-header"></div>
                                <div className="rule-header-cell">Destination</div>
                                <div className="rule-header-cell">Port</div>
                            </div>
                            <div className="rules-table-content">
                                {trafficClass.rules?.map((rule, index) => {
                                    return (
                                        <Draggable key={rule.index} draggableId={rule.index.toString()} index={index}>
                                            {(provided) => getRuleRow(rule, provided)}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </div>
                        </> : <div className="no-rules">No destinations</div>}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
});