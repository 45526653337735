import { IP_REGEX } from './constants';

export const hasDuplicatesValueOnArray = (array: any[]) => {
    const values = array.map(item => item.value);
    return new Set(values).size !== values.length;
}

export const validateIP = (ipAddress) => {
    const octets = ipAddress.split('.');
    if (octets.length === 4 && octets[2] === '*' && octets[3] !== '*') {
        return false;
    } else {
        return new RegExp(IP_REGEX).test(ipAddress);
    }
}

export const validatePort = (port) => {
    if (port === '*') {
        return true;
    }

    if (isNaN(parseInt(port)) || port <= 0 || port > 65535) {
        return false;
    }

    return true;
}

const KByte = 1 / 1024; // KB in a MB
const MByte = 1;
const GByte = 1024; // MB in a GB
const TByte = 1024 * GByte; // GB in a TB

export const convertMBUnits = (megabytes: number) => {
    let divideValue: number;
    let displayUnitMeasurement: string;

    if (megabytes >= TByte) {
        divideValue = TByte;
        displayUnitMeasurement = 'TB';
    } else if (megabytes >= GByte) {
        divideValue = GByte;
        displayUnitMeasurement = 'GB';
    } else if (megabytes >= MByte) {
        divideValue = MByte;
        displayUnitMeasurement = 'MB';
    } else {
        divideValue = KByte;
        displayUnitMeasurement = 'KB';
    }

    return { value: Number.parseFloat((megabytes / divideValue).toFixed(2)), unitName: displayUnitMeasurement };
};

export const convertMBUnitsToString = (megabytes: number) => {
    const converted = convertMBUnits(megabytes);
    return `${converted.value} ${converted.unitName}`;
};