import React, { useCallback, useState } from 'react';
import './settings.scss';
import { observer } from 'mobx-react-lite';
import { ReactComponent as Filter } from '../../../../../../assets/svg/filter.svg';
import { Sidebar } from '../../../../../shared/sidebar/sidebar';
import { SettingsSidebar } from './settings-sidebar';
import { useStores } from '../../../../../store';
import { RouterPrompt } from '../../../../../shared/router-prompt/router-prompt';

const Settings = observer(() => {
    const [showSidebar, setShowSidebar] = useState(false);
    const onSettingsIconClick = useCallback(() => setShowSidebar(true), []);
    const {useCaseStore, customerStore} = useStores();

    const getPathPrefix = (pathname: string) => {
        const lastIndex = pathname.lastIndexOf('/');
        return pathname.substring(0, lastIndex);
    }

    return (<>
            <div className="settings-wrapper">
                <div className={'setting-icon'} onClick={onSettingsIconClick}><Filter/></div>
                <Sidebar sidebarComponent={<SettingsSidebar/>} isOpen={showSidebar} setIsOpen={setShowSidebar}/>
            </div>
            <RouterPrompt isDirty={useCaseStore.isDirty}
                          restoreData={() =>  useCaseStore.loadValues(customerStore.selectedCustomer.name)}
                          getPath={getPathPrefix} />
        </>
    );
});

export default Settings;
