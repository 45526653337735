import React from 'react';
import './affected-devices.scss';
import TrendChart from '../security-dashboard/trend-chart/trend-chart';
import LineChart, { ColorType } from '../security-dashboard/line-chart/line-chart';

interface AffectedDevicesProps {
    points: any[];
    deviceNumber: number;
    percent: number;
    trendDirection: 'up' | 'down';
}

const AffectedDevices: React.FC<AffectedDevicesProps> = ({ points, deviceNumber, percent, trendDirection }) => {
    return (
        <div className="affected-devices">
            <div className="affected-devices-title">Affected Devices</div>
            <div className="device-number">{deviceNumber}</div>
            { points.length ?  <TrendChart
                percent={percent}
                trendDirection={trendDirection}
                trendColor="red"
                points={points}
                lineColor={ColorType.RED}
                height={120}
                width={190}
            /> : <div className="no-change">
                    <LineChart points={[
                        { x: 0, y: 30 },
                        { x: 100, y: 30 },
                    ]} colorType={ColorType.RED} width={200}/>
                    <div className="no-change-text">No Change</div>
            </div>
            }
        </div>
    );
};

export default AffectedDevices;
