import { useStores } from '../../../store';
import React, { useEffect, useMemo } from 'react';
import { InsightsSubMenu } from '../../../../utils/enums';
import { GrafanaWidget } from '../../grafana/grafana-widget';
import { createBaseWidgetUrl } from '../../../../utils/parse';
import { observer } from 'mobx-react-lite';

export const InsightsTrafficClasses = observer(() => {

    const { insightsStore, customerStore, useCaseStore } = useStores();

    useEffect(() => {
        insightsStore.setActiveSubMenu(InsightsSubMenu.TRAFFIC_CLASSES);
    }, [customerStore.selectedCustomer]);

    const baseWidgetUrl = useMemo(() => {
        const baseGrafanaUrl = customerStore.selectedCustomer?.isDemo ? useCaseStore.model?.demoBaseGrafanaUrl : useCaseStore.model?.baseGrafanaUrl;
        if (customerStore.selectedCustomer?.name && baseGrafanaUrl) {
            return createBaseWidgetUrl(baseGrafanaUrl, customerStore.selectedCustomer?.name, insightsStore.activeFilter.apns, insightsStore.activeFilter.dateRange, null, null, null);
        }
    }, [customerStore.selectedCustomer, insightsStore.activeFilter.apns, insightsStore.activeFilter.dateRange, useCaseStore.model]);

    return (
        <div className='insights-container'>
            <div className='title'>Traffic Classes</div>
            <div className='traffic-classes-widget'>
                {baseWidgetUrl &&
                    <GrafanaWidget panelId={insightsStore.trafficClassesPanelId}
                                   src={`${baseWidgetUrl}${insightsStore.trafficClassesPanelId}`}/>
                }
            </div>
        </div>
    );
});