import React from 'react';
import { Link } from 'react-router-dom';
import './recommended-action-list.scss';
import RecommendedAction from '../recommended-action/recommended-action';
import { Action } from '../../../../utils/model';

const RecommendedActionList: React.FC = () => {

    const items: Action[] = [
        {
            id: "1707652682446",
            title: "Volumetric Alert",
            desc: "This is a volumetric alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            status: "high"
        },
        {
            id: "2707652682446",
            title: "Download Alert",
            desc: "This is a download alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            status: "medium"
        },
        {
            id: "1707652917250",
            title: "Location Anomaly Alert",
            desc: "This is a location anomaly alert.",
            apnName: "mti-nprd@list.att.com",
            timestamp: 1732183859000,
            status: "low"
        },
        {
            id: "1707651241838",
            title: "Upload Alert",
            desc: "This is an upload alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            status: "medium"
        },
        {
            id: "1707651841951",
            title: "Upload Alert",
            desc: "This is another upload alert.",
            timestamp: 1732183859000,
            apnName: "adtaurp.adt.com"
            // status is optional and not provided here
        },
        {
            id: "1707651241831",
            title: "Upload Alert",
            desc: "This is an upload alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            status: "medium"
        },
        {
            id: "2707651841951",
            title: "Upload Alert",
            desc: "This is another upload alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            // status is optional and not provided here
        },
        {
            id: "1727651241838",
            title: "Upload Alert",
            desc: "This is an upload alert.",
            apnName: "adtaurp.adt.com",
            timestamp: 1732183859000,
            status: "medium"
        },
        {
            id: "1207651841951",
            title: "Upload Alert",
            desc: "This is another upload alert.",
            timestamp: 1732183859000,
            apnName: "adtaurp.adt.com"
            // status is optional and not provided here
        }
    ];

    const limitedItems = items.slice(0, 8); // Limit to the first 8 items

    return (
        <div className="recommended-action-list">
            <div className="recommended-action-title">
                Recommended Actions
            </div>
            {items.length > 8 && (
                <Link className="security-view-all" to={"/"}>
                    View All
                </Link>
            )}
            <div className="recommended-action-items">
                {limitedItems.map(action => (
                    <RecommendedAction key={action.id} action={action}/>
                ))}
            </div>
        </div>
    );
};

export default RecommendedActionList;
