import React, { useMemo, useRef, useState } from 'react';
import { Button } from 'antd';
import CustomModal from '../../../../../../shared/custom-modal/custom-modal';
import DestTypeGroup from '../dest-type-group';

import './select-destination-modal.scss';
import DestinationTree from '../../../../destination-tree/destination-tree';
import { CheckedNode } from '../../../../../../../dtos/traffic-dest.dto';
import { useStores } from '../../../../../../store';
import { observer } from 'mobx-react-lite';

export interface SelectDestinationModalProps {
    apnName? : string;
    closeModal?: () => void;
    activeType?: 'white' | 'black';
    preSelectedPercent?: number;
}
export const SelectDestinationModal = observer((props: SelectDestinationModalProps) => {
    const { useCaseStore } = useStores();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [destinationType, setDestinationType] = useState<'white' | 'black'>(props.activeType || 'white');
    const [checkedDestinations, setCheckedDestinations] = useState<string[]>([]);
    const destinationTreeRef = useRef<{ getFinalCheckedDestinations: () => CheckedNode[] }>(null);

    const destinationExistingList = useMemo(() =>
         useCaseStore.destValueByType(destinationType, props.apnName)
    , [destinationType, props.apnName, useCaseStore.apnPreferences]);

    const handleOk = () => {
        if (destinationTreeRef.current) {
            const finalCheckedDestinations = destinationTreeRef.current.getFinalCheckedDestinations();
            const combinedList = destinationExistingList.concat(finalCheckedDestinations);
            const uniqueCombinedList = Array.from(
                combinedList.reduce((map, item) => map.set(item.value, item), new Map()).values()
            );
            useCaseStore.changeParamValueByApn(props.apnName, `${destinationType}_list_destinations`, uniqueCombinedList);

        }
        handleCancel();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if (props.closeModal) {
            props.closeModal();
        }
    };

    return (
        <>
            <Button className={'add-destinations secondary-btn'} type="primary" onClick={() => setIsModalOpen(true)}>+ Destinations</Button>
            {isModalOpen && <CustomModal
                isOpen={isModalOpen}
                width={910}
                title={`Edit Destinations`}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Save changes"
                customClassName="select-dest-modal"
                okButtonProps={{ disabled: !checkedDestinations.length}}
                cancelButtonProps={{ className: 'secondary-btn' }}>
                <div className="select-dest-modal">
                    <div className="select-dest-modal-header">
                        <div className="apn-name">{props.apnName}</div>
                        { !props.activeType && <DestTypeGroup destinationType={destinationType} setDestinationType={setDestinationType}/> }
                    </div>
                    <div className="select-dest-modal-content">
                        <DestinationTree
                            ref={destinationTreeRef}
                            apn={props.apnName}
                            defaultCheckedDestinations={destinationExistingList.map(dest => dest.value)}
                            preSelectedPercent={props.preSelectedPercent}
                            onCheckedUpdate={setCheckedDestinations}/>
                    </div>
                </div>
            </CustomModal>}
        </>
    );
});
