import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as UpArrow } from '../../../../assets/svg/trend-up-arrow.svg';
import { ReactComponent as DownArrow } from '../../../../assets/svg/trend-down-arrow.svg';
import './table-issue.scss';
import { StatusColors } from '../security-dashboard.constant';
import { IssueDetail } from '../../../../utils/model';

interface TableIssueProps {
    categoryKey: string;
    categoryName: string;
    icon: ReactNode;
    data: IssueDetail[];
    total: number;
    color: string;
    onRowClick: (issue: IssueDetail) => void;
}

const TableIssue: React.FC<TableIssueProps> = ({ categoryKey, categoryName, icon, data, total, color, onRowClick }) => {
    return (
        <div className="table-issue">
            <div className="table-issue-header">
                <div className="table-issue-category">
                    <div className="icon">{icon}</div>
                    <div className="category-name">{categoryName}</div>
                    <div className="total-issues" style={{ backgroundColor: color }}>{total}</div>
                </div>
                {data.length > 0 && <Link className="security-view-all" to={`../security-issue-management?category=${categoryKey}`}>View All</Link>}
            </div>
            {data.length === 0 ? (
                <div className="empty-state">
                    <div className="empty-state-title">No {categoryName} Detected</div>
                    <div className="empty-state-desc">Your system is secure and free from any {categoryName}. Keep up the great work!</div>
                </div>
            ) : (
                <div className="table">
                    <div className="table-header">
                        {['Use Case', 'Device', 'APN'].map((header, index) => (
                            <div className="header-cell" key={header}>{header}</div>
                        ))}
                    </div>
                    {data.map((item) => (
                        <div className="table-row" key={item.id} onClick={() => onRowClick(item)}
                             onKeyDown={() => onRowClick(item)}>
                            <div className="cell use-case">
                                <div className="importance-wrapper">
                                    <div className="importance"
                                         style={{
                                             color: StatusColors[item.importance].color,
                                             borderColor: StatusColors[item.importance].color
                                         }}
                                    >
                                        {item.importance}
                                    </div>
                                </div>
                                <div>{item.title}</div>
                            </div>
                            <div className={`cell devices ${item.trend}`}>
                                <div className="device-number">{item.deviceNumber.toLocaleString('en-US')}</div>
                                {item.trend === 'up' ? <UpArrow /> : <DownArrow />}
                            </div>
                            <div className="cell apn-number">{item.apnList.length}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default TableIssue;
