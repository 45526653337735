import React from 'react';
import './help-item.scss';

interface HelpItemProps {
    title: string;
    description: string;
    IconComponent: React.FC;
}

const HelpItem: React.FC<HelpItemProps> = ({ title, description, IconComponent }) => {
    return (
        <div className="help-item">
            <IconComponent />
            <div className="help-item-content">
                <div className="help-item-title">{title}</div>
                <div className="help-item-desc">{description}</div>
            </div>
        </div>
    );
}

export default HelpItem;
